*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-gray-400);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: var(--chakra-colors-gray-500);
}
html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}
